<!--
 * @Description: 确认订单页面组件
 * @Author: hai-27
 * @Date: 2020-02-23 23:46:39
 * @LastEditors: hai-27
 * @LastEditTime: 2020-03-29 13:10:21
 -->
<template>
	<div class="confirmOrder">
		<!-- 头部 -->
		<div class="confirmOrder-header">
			<div class="header-content">
				<!-- <p>
					<i class="el-icon-s-order"></i>
				</p> -->
				<p>确认订单</p>
				<router-link to></router-link>
			</div>
		</div>
		<!-- 头部END -->

		<!-- 主要内容容器 -->
		<div class="content">
			<!-- 选择地址 -->
			<div class="section-address">
				<p class="title">收货地址</p>
				<div class="address-body">
					<ul>
						<li :class="index == confirmAddress ? 'in-section' : ''" v-for="(item, index) in addlist"
							:key="index" @click="clickdizhi(index)">
							<h2>{{ item.Consignee }}</h2>
							<p class="phone">{{ item.Mobile }}</p>
							<p class="address">{{ item.Address }}</p>
						</li>
						<router-link to="/PersonalCenter/Address">
							<li class="add-address">
								<i class="el-icon-circle-plus-outline"></i>
								<p>添加新地址</p>
							</li>
						</router-link>
					</ul>
				</div>
			</div>
			<!-- 选择地址END -->

			<!-- 商品及优惠券 -->
			<div class="section-goods">
				<p class="title">商品</p>
				<div class="goods-list" v-if="type == 1">
					<ul>
						<li v-for="(item, index) in getShoppingCart" :key="index">
							<div v-if="item.check">
								<img :src="getUrl(item.GoodsUrl)" />
								<span class="pro-name">{{ item.SkuName }}</span>
								<span class="pro-price">{{ item.Price }}元 x {{ item.Num }}</span>
								<span class="pro-status"></span>
								<span class="pro-total">{{ item.Price * item.Num }}元</span>
							</div>
						</li>
					</ul>
				</div>
				<div class="goods-list" v-if="type == 2">
					<ul v-if="newShoppingCart.GoodsList&&newShoppingCart.GoodsList.length>0">
						<li v-for="(item, index) in newShoppingCart.GoodsList[0].GoodsList" :key="index">
							<img :src="getUrl(item.GoodsUrl)" />
							<span class="pro-name">{{ item.SkuName }}</span>
							<span class="pro-price">{{ item.Price }}元 x {{ item.Num }}</span>
							<span class="pro-status"></span>
							<span class="pro-total">{{ item.Price * item.Num }}元</span>
						</li>
					</ul>
				</div>

			</div>
			<!-- 商品及优惠券END -->

			<!-- 配送方式 -->
			<div class="section-shipment">
				<p class="title">配送方式</p>
				<p class="shipment">包邮</p>
			</div>
			<!-- 配送方式END -->
			<!-- 支付方式 -->
			<div class="section-goods">
				<p class="title">支付方式</p>
				<p class="goods-list layui-form">
					<el-select v-model="payType" placeholder="请选择">
						<el-option v-for="(item,index) in payTypeArr" :key="index" :label="item.Name" :value="item.Id">
						</el-option>
					</el-select>
				</p>
			</div>
			<div class="section-goods" v-if="payType==3">
				<p class="title">支付银行卡</p>
				<p class="goods-list layui-form">
					<el-select v-model="BankId" placeholder="请选择">
						<el-option v-for="(item,index) in blist" :key="index" :label="item.BankCard" :value="item.Id">
						</el-option>
					</el-select>
				</p>
			</div>
			<!-- 支付方式END -->

			<!-- 发票 -->
			<!-- <div class="section-invoice">
        <p class="title">发票</p>
        <p class="invoice">电子发票</p>
        <p class="invoice">个人</p>
        <p class="invoice">商品明细</p>
      </div> -->
			<!-- 发票END -->

			<!-- 结算列表 -->
			<div class="section-count" v-if="type == 1">
				<div class="money-box">
					<ul>
						<li>
							<span class="title">商品件数：</span>
							<span class="value">{{ getCheckNum }}件</span>
						</li>
						<li>
							<span class="title">商品总价：</span>
							<span class="value">{{ getTotalPrice }}元</span>
						</li>
						<!-- <li>
	          <span class="title">活动优惠：</span>
	          <span class="value">-0元</span>
	        </li>
	        <li>
	          <span class="title">优惠券抵扣：</span>
	          <span class="value">-0元</span>
	        </li> -->
						<li>
							<span class="title">运费：</span>
							<span class="value">0元</span>
						</li>
						<li class="total">
							<span class="title">应付总额：</span>
							<span class="value">
								<span class="total-price">{{ getTotalPrice }}</span>元
							</span>
						</li>
					</ul>
				</div>
			</div>
			<div class="section-count" v-if="type == 2">
				<div class="money-box">
					<ul>
						<li>
							<span class="title">商品件数：</span>
							<span class="value">{{ newShoppingCart.goodsTotal }}件</span>
						</li>
						<li>
							<span class="title">商品总价：</span>
							<span class="value">{{ newShoppingCart.orderTotal }}元</span>
						</li>
						<!-- <li>
              <span class="title">活动优惠：</span>
              <span class="value">-0元</span>
            </li>
            <li>
              <span class="title">优惠券抵扣：</span>
              <span class="value">-0元</span>
            </li> -->
						<li>
							<span class="title">运费：</span>
							<span class="value">0元</span>
						</li>
						<li class="total">
							<span class="title">应付总额：</span>
							<span class="value">
								<span class="total-price">{{ newShoppingCart.orderTotal }}</span>元
							</span>
						</li>
					</ul>
				</div>
			</div>
			<!-- 结算列表END -->

			<!-- 结算导航 -->
			<div class="section-bar">
				<div class="btn">
					<router-link to="/shoppingCart" class="btn-base btn-return">返回购物车</router-link>
					<a href="javascript:void(0);" @click="CreteOrder" class="btn-base btn-primary">结算</a>
				</div>
			</div>
			<!-- 结算导航END -->
		</div>
		<!-- 主要内容容器END -->
		<el-dialog title="交易确认" :visible.sync="dialogVisible" width="80%" destroy-on-close
			:close-on-click-modal="false">
			<div>
				<el-form ref="form1" :model="protocolPayConfirmQuery" label-width="100px">
					<el-form-item label="订单号">
						<el-input v-model="protocolPayConfirmQuery.token" :disabled="true"></el-input>
					</el-form-item>
					<el-form-item label="协议号">
						<el-input v-model="protocolPayConfirmQuery.protocol" :disabled="true"></el-input>
					</el-form-item>
					<el-form-item label="短信验证码">
						<el-input v-model="protocolPayConfirmQuery.smsCode"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" style="background-color: #0C6A6A;border: none;" :disabled="IsChecked"
							@click="SubmitprotocolPayConfirm">提交</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		mapGetters
	} from "vuex";
	import {
		mapActions
	} from "vuex";
	export default {
		name: "",
		data() {
			return {
				// 虚拟数据
				confirmAddress: 0, // 选择的地址id
				// 地址列表
				address: [],
				addlist: [],
				type: null,
				newShoppingCart: {},
				blist: [],
				payType: 4,
				showz: false,
				BankId: 0,
				protocolPayConfirmQuery: {
					token: '',
					protocol: '',
					smsCode: ''
				},
				dialogVisible: false,
				IsChecked: false,
				payTypeArr: [{
					Id: 3,
					Name: '快捷支付'
				}, {
					Id: 4,
					Name: '易票联扫码支付'
				}]
			};
		},
		created() {
			// 如果没有勾选购物车商品直接进入确认订单页面,提示信息并返回购物车
			console.log(this.newShoppingCart, 123)
			if (this.type == 1 && this.getCheckNum < 1) {
				this.notifyError("请勾选商品后再结算");
				this.$router.push({
					path: "/shoppingCart"
				});
			}

		},
		activated() {
			this.addresslist()
			this.GetUserBankList()
		},
		async mounted() {
			this.addresslist()
			var query = this.$route.query;
			this.type = query.type;
			if (this.type == 2) {				
				const res = await this.postdata('/Order/CreteOrderView', {
					GoodsId: query.GoodsId,
					SkuId: query.SkuId,
					Num: query.Num
				})
				if (res.code == 200) {
					this.newShoppingCart = res.data
					this.$nextTick(() => {
						this.showz = true
					});
					console.log(this.newShoppingCart)
				}
			}

		},
		computed: {
			// 结算的商品数量; 结算商品总计; 结算商品信息
			...mapGetters(["getCheckNum", "getTotalPrice", "getCheckGoods", "getShoppingCart", ])
		},
		methods: {
			...mapActions(["deleteShoppingCart"]),
			// addOrder() {
			//   this.$axios
			//     .post("/user/order/addOrder", {
			//       user_id: this.$store.getters.getUser.user_id,
			//       products: this.getCheckGoods
			//     })
			//     .then(res => {
			//       let products = this.getCheckGoods;
			//       switch (res.data.code) {
			//         // “001”代表结算成功
			//         case "001":
			//           for (let i = 0; i < products.length; i++) {
			//             const temp = products[i];
			//             // 删除已经结算的购物车商品
			//             this.deleteShoppingCart(temp.id);
			//           }
			//           // 提示结算结果
			//           this.notifySucceed(res.data.msg);
			//           // 跳转我的订单页面
			//           this.$router.push({ path: "/order" });
			//           break;
			//         default:
			//           // 提示失败信息
			//           this.notifyError(res.data.msg);
			//       }
			//     })
			//     .catch(err => {
			//       // return Promise.reject(err);
			//     });
			// },

			clickdizhi(index) {
				this.confirmAddress = index
			},
			async addresslist() {
				var that = this
				const res = await this.postdata('/User/AddressList', {
					page: 1,
					limit: 20
				})
				if (res.code == 200) {
					this.addlist = res.data.items
				}
			},
			async CreteOrder() {
				var that = this
				var payType = that.payType
				if (payType == 3 && that.BankId == 0) {
					this.notifyError('请选择支付的银行卡');
					return false
				}
				if (this.type == 1) {
					var id = []
					this.getShoppingCart.map((item, index) => {
						if (item.check) {
							id.push(item.Id)
						}
					})
					id.push()
					if (this.addlist.length <= 0) {
						this.notifyError('请先新增地址');
						return false
					}
					if (!this.addlist[this.confirmAddress].Id) {
						this.notifyError('请选择地址');
						return false
					}
					const res = await this.postdata('/Order/CreteOrder', {
						"CartIds": id,
						"GoodsId": 0,
						"AddressId": this.addlist[this.confirmAddress].Id,
						Type: 1
					})
					if (res.code == 200) {
						this.notifySucceed('提交成功');
						this.orderpay(res.data)
						this.$router.push("/PersonalCenter/Order")
					}
				} else if (this.type == 2) {
					var goods = this.newShoppingCart.GoodsList[0]
					console.log(goods)
					if (this.addlist.length <= 0) {
						this.notifyError('请先新增地址');
						return false
					}
					if (!this.addlist[this.confirmAddress].Id) {
						this.notifyError('请选择地址');
						return false
					}
					const res = await this.postdata('/Order/CreteOrder', {
						"CartIds": [],
						"GoodsId": goods.GoodsList[0].GoodsId,
						"AddressId": this.addlist[this.confirmAddress].Id,
						"SkuId": goods.GoodsList[0].SkuId,
						"Num": goods.GoodsList[0].Num,
						ShopId: goods.ShopId,
						Type: 1
					})
					if (res.code == 200) {
						this.notifySucceed('提交成功');
						if (payType == 3) this.orderpay(res.data)
						else if (payType == 4) {
							this.$router.push({
								path: "/payQrCode",
								query: {
									OrderNo: res.data
								}
							})
						}
						//this.$router.push("/PersonalCenter/Order")
					}
				}

			},

			async orderpay(orderno) {
				var payType = this.payType
				var data = {
					OrderNo: orderno,
					PayType: this.payType,
					BankId: this.BankId
				}
				const res = await this.postdata('/Order/OrderAgainPay', data)
				console.log('res', res)
				if (res.code == 200) {
					var result = res.data
					console.log(result)
					if (payType == 3) {
						if (result.returnCode != '0000') {
							this.notifyError(result.returnMsg);
						} else {
							//this.notifySucceed('支付成功');
							this.protocolPayConfirmQuery.token = result.token
							this.protocolPayConfirmQuery.protocol = this.blist.find(it => it.Id == this.BankId)
								.protocol
							this.dialogVisible = true
						}
					}

					// if (this.value == 6) {
					// 	var win = window.open(); //打开新的空白窗口
					// 	win.document.write(res.data);
					// 	win.document.forms[0].submit();
					// } else {
					// 	//银行卡支付
					// 	window.open(res.data, "_blank");
					// }
				}
			},
			async GetUserBankList() {
				const res = await this.postdata('/User/UserBankList', {
					page: 1,
					limit: 100,
					isLike: 1
				})
				if (res.code == 200) {
					this.blist = res.data.items
				}
			},
			async SubmitprotocolPayConfirm() {
				this.IsChecked = true
				const res = await this.postdata('/Order/epay_protocolPayConfirm', this.protocolPayConfirmQuery)
				this.IsChecked = false
				if (res.code == 200) {
					var result = res.data
					console.log(result)
					if (result.returnCode != '0000') {
						this.notifyError(result.returnMsg);
					} else {
						this.notifySucceed('支付处理中');
						this.$router.push("/PersonalCenter/Order")
					}
				}
			}
		}
	};
</script>
<style scoped>
	.confirmOrder {
		background-color: #f5f5f5;
		padding-bottom: 20px;
	}

	/* 头部CSS */
	.confirmOrder .confirmOrder-header {
		background-color: #fff;
		border-bottom: 2px solid #967D67;
		margin-bottom: 20px;
	}

	.confirmOrder .confirmOrder-header .header-content {
		width: 1440px;
		margin: 0 auto;
		height: 80px;
	}

	.confirmOrder .confirmOrder-header .header-content p {
		float: left;
		font-size: 28px;
		line-height: 80px;
		color: #424242;
		margin-right: 20px;
	}

	.confirmOrder .confirmOrder-header .header-content p i {
		font-size: 45px;
		color: #ff6700;
		line-height: 80px;
	}

	/* 头部CSS END */

	/* 主要内容容器CSS */
	.confirmOrder .content {
		width: 1440px;
		margin: 0 auto;
		padding: 48px 0 0;
		background-color: #fff;
	}

	/* 选择地址CSS */
	.confirmOrder .content .section-address {
		margin: 0 48px;
		overflow: hidden;
	}

	.confirmOrder .content .section-address .title {
		color: #333;
		font-size: 18px;
		line-height: 20px;
		margin-bottom: 20px;
	}

	.confirmOrder .content .address-body li {
		float: left;
		color: #333;
		width: 220px;
		height: 178px;
		border: 1px solid #e0e0e0;
		padding: 15px 24px 0;
		margin-right: 17px;
		margin-bottom: 24px;
	}

	.confirmOrder .content .address-body .in-section {
		border: 1px solid #603c1d;
	}

	.confirmOrder .content .address-body li h2 {
		font-size: 18px;
		font-weight: normal;
		line-height: 30px;
		margin-bottom: 10px;
	}

	.confirmOrder .content .address-body li p {
		font-size: 14px;
		color: #757575;
	}

	.confirmOrder .content .address-body li .address {
		padding: 10px 0;
		max-width: 180px;
		max-height: 88px;
		line-height: 22px;
		overflow: hidden;
	}

	.confirmOrder .content .address-body .add-address {
		text-align: center;
		line-height: 30px;
	}

	.confirmOrder .content .address-body .add-address i {
		font-size: 30px;
		padding-top: 50px;
		text-align: center;
	}

	/* 选择地址CSS END */

	/* 商品及优惠券CSS */
	.confirmOrder .content .section-goods {
		margin: 0 48px;
	}

	.confirmOrder .content .section-goods p.title {
		color: #333;
		font-size: 18px;
		line-height: 40px;
	}

	.confirmOrder .content .section-goods .goods-list {
		padding: 5px 0;
		border-top: 1px solid #e0e0e0;
		border-bottom: 1px solid #e0e0e0;
	}

	.confirmOrder .content .section-goods .goods-list li {
		padding: 10px 0;
		color: #424242;
		overflow: hidden;
		display: flex;
		align-items: center;
	}

	.confirmOrder .content .section-goods .goods-list li img {
		float: left;
		width: 90px;
		height: 90px;
		margin-right: 10px;
	}

	.confirmOrder .content .section-goods .goods-list li .pro-name {
		float: left;
		width: 590px;
		line-height: 36px;
		font-size: 16px;
	}

	.confirmOrder .content .section-goods .goods-list li .pro-price {
		float: left;
		width: 150px;
		text-align: center;
		line-height: 36px;
		font-size: 16px;
	}

	.confirmOrder .content .section-goods .goods-list li .pro-status {
		float: left;
		width: 99px;
		height: 30px;
		text-align: center;
		line-height: 36px;
		font-size: 16px;
	}

	.confirmOrder .content .section-goods .goods-list li .pro-total {
		float: left;
		width: 190px;
		text-align: center;
		color: #ff6700;
		line-height: 36px;
		font-size: 16px;
	}

	/* 商品及优惠券CSS END */

	/* 配送方式CSS */
	.confirmOrder .content .section-shipment {
		margin: 0 48px;
		padding: 16px 0;
		border-bottom: 1px solid #e0e0e0;
		overflow: hidden;
	}

	.confirmOrder .content .section-shipment .title {
		float: left;
		width: 150px;
		color: #333;
		font-size: 18px;
		line-height: 38px;
	}

	.confirmOrder .content .section-shipment .shipment {
		float: left;
		line-height: 38px;
		font-size: 14px;
		color: #ff6700;
	}

	/* 配送方式CSS END */

	/* 发票CSS */
	.confirmOrder .content .section-invoice {
		margin: 0 48px;
		padding: 25px 0;
		border-bottom: 1px solid #e0e0e0;
		overflow: hidden;
	}

	.confirmOrder .content .section-invoice .title {
		float: left;
		width: 150px;
		color: #333;
		font-size: 18px;
		line-height: 38px;
	}

	.confirmOrder .content .section-invoice .invoice {
		float: left;
		line-height: 38px;
		font-size: 14px;
		margin-right: 20px;
		color: #ff6700;
	}

	/* 发票CSS END */

	/* 结算列表CSS */
	.confirmOrder .content .section-count {
		margin: 0 48px;
		padding: 20px 0;
		overflow: hidden;
	}

	.confirmOrder .content .section-count .money-box {
		float: right;
		text-align: right;
	}

	.confirmOrder .content .section-count .money-box .title {
		float: left;
		width: 126px;
		height: 30px;
		display: block;
		line-height: 30px;
		color: #757575;
	}

	.confirmOrder .content .section-count .money-box .value {
		float: left;
		min-width: 105px;
		height: 30px;
		display: block;
		line-height: 30px;
		color: #ff6700;
	}

	.confirmOrder .content .section-count .money-box .total .title {
		padding-top: 15px;
	}

	.confirmOrder .content .section-count .money-box .total .value {
		padding-top: 10px;
	}

	.confirmOrder .content .section-count .money-box .total-price {
		font-size: 30px;
	}

	/* 结算列表CSS END */

	/* 结算导航CSS */
	.confirmOrder .content .section-bar {
		padding: 20px 48px;
		border-top: 2px solid #f5f5f5;
		overflow: hidden;
	}

	.confirmOrder .content .section-bar .btn {
		float: right;
	}

	.confirmOrder .content .section-bar .btn .btn-base {
		float: left;
		margin-left: 30px;
		width: 158px;
		height: 38px;
		border: 1px solid #b0b0b0;
		font-size: 14px;
		line-height: 38px;
		text-align: center;
	}

	.confirmOrder .content .section-bar .btn .btn-return {
		color: rgba(0, 0, 0, 0.27);
		border-color: rgba(0, 0, 0, 0.27);
	}

	.confirmOrder .content .section-bar .btn .btn-primary {
		background: #603c1d;
		border-color: #603c1d;
		color: #fff;
	}

	/* 结算导航CSS */

	/* 主要内容容器CSS END */
</style>